import {
  ItemContainer,
  SectionContainer,
  SectionDescription,
  StdLink,
} from "./atoms";

const EXAMPLES = [
  [
    'https://www.tungsteno.io/post/app-pythagorean_decomposition_1',
    'Pythagorean decompositions',
    ': six proofs of the Pythagorean Theorem by means of isometric decompositions.',
  ],[
    'https://www.youtube.com/watch?v=wADfra0U4SE',
    'Resolution of the Reduced Cubic Equation',
    ': an animation on Cardano\'s formula.',
  ],[
    'https://www.tungsteno.io/post/exp-regular_heptadecagon',
    'The Regular Heptadecagon',
    ': an exposition of the constructibility of the regular heptadecagon with rule and compass, with a taste of Galois Theory.',
  ],[
    'https://www.tungsteno.io/post/exp-apollonius_tangency_problem/',
    'The Tangency Problem of Apollonius',
  ],[
    'https://www.tungsteno.io/post/exp-why_do_dodecahedra_have_12_pentagons/',
    'Why do Dodecahedra Have 12 Pentagons',
    ' (for 3b1b Summer of Math Exposition #1): a highly interactive exposition of a little known fact of regular polyhedra.',
  ],[
    'https://www.tungsteno.io/post/exp-knot_gallery/',
    'Knot Gallery',
  ],[
    'https://www.tungsteno.io/turing-machine',
    'Turing Machine',
    ', a simulation of Alan Turing computational abstract machine with several interesting examples.'
  ],[
    'https://www.tungsteno.io/post/app-tileturn_1/',
    'Tileturn',
    ', a game that will blow your mind!!'
  ],
];

function Content() {
  return (
    <SectionContainer>
      <ItemContainer>
        <SectionDescription>

          Some math content that I really like:
          <ul>
            {EXAMPLES.map(([url,title,desc]) =>
              <li>
                <StdLink link={title} href={url} />{desc}
              </li>
            )}
          </ul>
        </SectionDescription>
      </ItemContainer>
    </SectionContainer>
  )
}

export default Content;