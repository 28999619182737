import pfx from '../../media/pfx.png';
import pfx_h from '../../media/pfx_h.png';

import cns from '../../media/cns.png';
import cns_h from '../../media/cns_h.png';

import tfg from '../../media/tfg.png';
import tfg_h from '../../media/tfg_h.png';

import tfm from '../../media/tfm.png';
import tfm_h from '../../media/tfm_h.png';

import cns_doc from '../../media/cns.pdf';
import tfg_doc from '../../media/tfg.pdf';
import tfm_doc from '../../media/tfm.pdf';

import w74 from '../../media/w74.png';
import w74_h from '../../media/w74_h.png';

import {
  ItemTitle,
  ItemCont,
  ItemLink,
  ItemDesc,
  ItemPic,
  Item,
  ItemContainer,
  SectionContainer,
} from "./atoms";

function Works() {
  return (
    <SectionContainer>

      <ItemContainer title={"Programming"}>

        <Item>
          <ItemPic img={pfx} imgh={pfx_h} alt="Polymorphic Fixpoints"/>
          <ItemDesc>
            <ItemTitle>Polymorphic Fixpoints: <br/>get rid of inheritance castings!</ItemTitle>
            <ItemCont>Devo Engineering Blog</ItemCont>
            <ItemLink
              href="https://devoinc.github.io/java/2021/06/29/polymorphic-fixpoints-get-rid-inheritance-castings.html"
              link="link"
            />
          </ItemDesc>
        </Item>

      </ItemContainer>

      <ItemContainer title={"Mathematics"}>

        <Item>
          <ItemPic img={w74} imgh={w74_h} alt="Tungsteno"/>
          <ItemDesc>
            <ItemTitle>Tungsteno</ItemTitle>
            <ItemCont>Maths for Everybody</ItemCont>
            <ItemLink
              href="https://www.tungsteno.io/"
              link="link"
            />
          </ItemDesc>
        </Item>

        <Item>
          <ItemPic img={cns} imgh={cns_h} alt="Cónicas"/>
          <ItemDesc>
            <ItemTitle>Cónicas</ItemTitle>
            <ItemCont>High School Final Project</ItemCont>
            <ItemLink
              href={cns_doc}
              link="pdf"
              download="cns.pdf"
            />
          </ItemDesc>
        </Item>

        <Item>
          <ItemPic img={tfg} imgh={tfg_h} alt="Introducción a la Cohomología Diferencial"/>
          <ItemDesc>
            <ItemTitle>Introducción a la <br/>Cohomología Diferencial</ItemTitle>
            <ItemCont>Mathematics Degree Final Project</ItemCont>
            <ItemLink
              href={tfg_doc}
              link="pdf"
              download="tfg.pdf"
            />
          </ItemDesc>
        </Item>

        <Item>
          <ItemPic img={tfm} imgh={tfm_h} alt="El Teorema de Calabi-Yau"/>
          <ItemDesc>
            <ItemTitle>El Teorema de <br/>Calabi-Yau</ItemTitle>
            <ItemCont>Advanced Mathematics <br/>Master's Degree Final Project</ItemCont>
            <ItemLink
              href={tfm_doc}
              link="pdf"
              download="tfm.pdf"
            />
          </ItemDesc>
        </Item>

      </ItemContainer>
    </SectionContainer>
  )
}

export default Works;