export function ItemTitle(props) {
  return (
    <p className="title item-title">{props.children}</p>
  );
}

export function ItemCont(props) {
  return (
    <p>{props.children}</p>
  );
}

export function StdLink(props) {
  return (
      <a
        className="item-link"
        href={props.href}
        download={props.download}
        target={!props.download && "_blank"}
        rel={!props.download && "noreferrer"}
        style={props.style}
      >
        {props.link}
      </a>
  );
}

export function ItemLink(props) {
  return (
    <p>
      {"[ "}
      <StdLink style={{textTransform: 'uppercase'}} {...props}/>
      {" ]"}
    </p>
  );
}

export function ItemLinks(props) {
  return (
    <p>
      {"[ "}
      {[...props.items.map(p =>
        <StdLink style={{textTransform: 'uppercase'}} {...p}/>)
      ].flatMap(e => [' | ', e]).slice(1)}
      {" ]"}
    </p>
  );
}

export function ItemDesc(props) {
  return (
    <div className="item-desc">{props.children}</div>
  );
}

export function ItemPic(props) {
  return (
    <div className="item-picture">
      <div className="picture-frame">
        <img
          src={props.img}
          className={"item-picture-img" + (props.noBorder ? "" : "")} // "-border"
          alt={props.alt}/>
        { props.imgh && <img src={props.imgh} className="item-picture-img hover" alt={props.alt} /> /* "-border" */}
      </div>
    </div>
  );
}

export function Item(props) {
  return (
    <div className="item">{props.children}</div>
  )
}

export function ItemContainer(props) {
  return (
    <div>
      { props.title && <h1 style={{paddingLeft: '30px', paddingTop: '30px'}}>{props.title}</h1> }
      { props.children }
    </div>
  )
}

export function SectionContainer(props) {
  return (
    <div className="section-container">
      <div className="section">
        { props.children }
      </div>
    </div>
  )
}

export function SectionDescription(props) {
  return (
    <div className="section-description">
      { props.children }
    </div>
  )
}