
import './App.css';
import Xme from './components/Xme';

function App() {
  return (
    <div className="App">
      <Xme />
    </div>
  );
}

export default App;
