import me from '../../media/me.jpeg';

import tw from '../../media/twitter.png';
import lk from '../../media/linkedin.png';
import gh from '../../media/github.png';
import cv from '../../media/cv.png';

import cv_doc from '../../media/cv.pdf';

function Social(props) {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      <img src={props.img} className="social" alt={props.alt} />
    </a>
  );
}

function Home() {
  return (
    <div className="about-container">
      <div className="about">
        <div className="picture">
          <img src={me} className="Me" alt="Juanjo Madrigal" />
        </div>
        <div className="description">
          <p className="big-title">Hi, I'm <span className="keyword">Juanjo</span> !</p>
          <p className="title">I like maths <br/> and programming</p>
          <Social img={tw} alt="Twitter"  href="https://twitter.com/jxm_math" />
          <Social img={lk} alt="LinkedIn" href="https://www.linkedin.com/in/juan-jose-madrigal/" />
          <Social img={gh} alt="Github"   href="https://github.com/jxm-math" />
          <span style={{paddingLeft: '30px'}}>
            <a
              href={cv_doc}
              download={"cv.pdf"}
            >
              <img src={cv} className="social" alt={"Curriculum Vitae"} />
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Home;