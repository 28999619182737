import { BrowserRouter, Routes, Route, Link, useLocation } from "react-router-dom";

import './Xme.css';

import Home from "./Home";
import About from "./About";
import Speaking from "./Speaking";
import Works from "./Works";
import Content from "./Content";

function Nav() {
  const navs = [
    ['home',    ''],
    ['about',   'about'],
    ['speaking','speaking'],
    ['works',   'works'],
  ];
  const loc = useLocation().pathname;
  return (
    <ul className="nav">
      {navs.map(([name,route]) => (
        <Link
          to={`/${route}`}
          key={`nav__${name}`}
          className={"nav-item" + ((loc === '/'+route) ? " nav-item-selected" : "")}
        >
          {name.toUpperCase()}
        </Link>
      ))}
    </ul>
  )
}

function Xme() {
  return (
    <BrowserRouter>
      <div className="Xme">
        <Nav />
        <div className="content">
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/speaking" element={<Speaking />} />
            <Route path="/works" element={<Works />} />
            <Route path="/content" element={<Content /> /* unlisted */} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default Xme;