import about from '../../media/about.png';

import {
  ItemCont,
  ItemDesc,
  ItemPic,
  Item,
  ItemContainer,
  SectionContainer,
  SectionDescription,
  StdLink,
} from "./atoms";

function About() {
  return (
    <SectionContainer>
      <ItemContainer>

        <Item>
          <ItemPic img={about} alt="Hi, I'm Juanjo!" noBorder/>
          <ItemDesc>
            <ItemCont>
              Hi there!!
              <br/><br/>
              I'm Juanjo Madrigal.
              I'm mathematician and software engineer.
              <br/><br/>
              In my free time I like to learn new things,
              go out with my bike, visit new places and hang out with friends.
              <br/><br/>
              I think there are many opportunities to make the world a better place to live.
            </ItemCont>
          </ItemDesc>
        </Item>
        <SectionDescription>
          I studied my Degree in Mathematics at Universidad Complutense de Madrid (2009-2013)
          and I followed the Pure Mathematics - Geometry & Topology path.
          Algebraic Topology is one of my favourite subjects.
          <br/><br/>
          Some years ago I started to study computer science topics:
          machine learning, neural networks and some time later, general programming.
          I have found it fascinating and much related with my math background.
          <br/><br/>
          I currently work at <StdLink link="Devo" href="https://www.devo.com/" />.
          It's a really good place to work.
          <br/><br/>
          <br/><br/>
          Random facts about me:
          <ul>
            <li> I love Haskell &lt;3 </li>
            <li> I'm a typography nerd.
              <br/> I use Source Code Pro a lot for my presentations, it's terrific.
              <br/> I'm also very interested in math typesetting. Lucida Bright and GFS Neohellenic Math are among my favorite math fonts.
            </li>
            <li> I participated in the 49<sup>th</sup> International Mathematics Olympiad (2008) as part of the Spanish team. </li>
            <li> Pixar films are incredible. </li>
            <li> Some day I want to learn to dance like Michael Jackson in <em>Smooth Criminal</em> or <em>Bad</em>. Love it. Some day. </li>
          </ul>
        </SectionDescription>
      </ItemContainer>
    </SectionContainer>
  )
}

export default About;